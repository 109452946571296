exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archiv-js": () => import("./../../../src/pages/Archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-chor-js": () => import("./../../../src/pages/Chor.js" /* webpackChunkName: "component---src-pages-chor-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-default-page-js": () => import("./../../../src/pages/defaultPage.js" /* webpackChunkName: "component---src-pages-default-page-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-termine-js": () => import("./../../../src/pages/Termine.js" /* webpackChunkName: "component---src-pages-termine-js" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/Verein.js" /* webpackChunkName: "component---src-pages-verein-js" */)
}

